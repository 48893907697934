<template>
  <vx-card
    class="cardx"
    remove-card-action
    :title="title"
    title-color="primary"
    @remove="$emit('remove')">

    <div class="vx-row mb-4">
      <div class="vx-col mb-4 w-full md:w-1/2" v-show="lodash.get(tracking, 'id')">
        <vs-input
          class="w-full"
          label="Created By"
          :value="createdBy"
          color="dark"
          disabled/>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-show="lodash.get(tracking, 'id')">
        <vs-input
          class="w-full"
          label="Created At"
          :value="createdAt"
          color="dark"
          disabled/>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Handling</label>
        <div class="vs-con-input mt-2">
          <ul class="flex">
            <li class="mx-1">
              <vs-radio
                v-model="tracking.type"
                vs-value="inbound"
                name="handling"
                v-validate="'required'">
                Inbound
              </vs-radio>
            </li>
            <li class="mx-1">
              <vs-radio
                v-model="tracking.type"
                name="handling"
                vs-value="outbound"
                v-validate="'required'">
                Outbound
              </vs-radio>
            </li>
          </ul>
        </div>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('handling')">
          {{ errors.first('handling') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input
          class="w-full"
          label="Current Child Stage"
          :value="currentStageTitle"
          color="dark"
          readonly />
<!--        <v-select
          v-model="tracking.outbound_stage_id"
          class="w-full"
          name="outbound_stage"
          color="dark"
          :clearable="false"
          :options="optionsOutboundStage"
          :reduce="stage => stage.value"
          v-validate="'required'"/>-->
        <span class="text-danger text-sm block mt-2" v-show="errors.has('outbound_stage')">
          {{ errors.first('outbound_stage') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Topic</label>
        <v-select
          v-model="topicId"
          name="topic"
          class="w-full"
          color="dark"
          :clearable="false"
          :options="optionsTopic"
          :reduce="codeReducer"
          v-validate="'required'"
          :disabled="!tracking.type"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('topic')">
          {{ errors.first('topic') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Sub Topic</label>
        <v-select
          class="w-full"
          v-model="tracking.tracking_topic_id"
          name="subtopic"
          color="dark"
          :clearable="false"
          :options="optionsSubTopic"
          :reduce="codeReducer"
          v-validate="'required'"
          :disabled="!topicId"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('subtopic')">
          {{ errors.first('subtopic') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Product</label>
        <v-select
          v-model="tracking.tracking_product"
          class="w-full"
          name="product"
          color="dark"
          :options="optionsProduct"
          :reduce="product => product.slug"
          :disabled="!tracking.type"
          v-validate="'required'"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('product')">
          {{ errors.first('product') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Buy Product From</label>
        <v-select
          v-model="tracking.tracking_store"
          class="w-full"
          name="store"
          color="dark"
          :options="optionsStore"
          :reduce="store => store.slug"
          :disabled="!tracking.type"
          v-validate="'required'"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('store')">
          {{ errors.first('store') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full">
        <label class="vs-input--label">Worknote</label>
        <vs-textarea
          v-model="tracking.notes"
          class="mb-0"
          label="Fill your work note here..."
          name="worknote"
          v-validate="'required'"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('worknote')">
          {{ errors.first('worknote') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Call Status</label>
        <div class="vs-con-input mt-2">
          <ul class="flex">
            <li class="mx-1">
              <vs-radio
                v-model="tracking.status"
                vs-value="success"
                name="status"
                v-validate="'required'">
                Success
              </vs-radio>
            </li>
            <li class="mx-1">
              <vs-radio
                v-model="tracking.status"
                vs-value="fail"
                name="status"
                v-validate="'required'">
                Fail
              </vs-radio>
            </li>
          </ul>
        </div>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('status')">
          {{ errors.first('status') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-if="tracking.status === 'fail'">
        <label class="vs-input--label">Fail Reason</label>
        <v-select
          v-model="tracking.tracking_reason_id"
          class="w-full"
          name="fail_reason"
          color="dark"
          :options="failReasons | labelCode"
          :reduce="codeReducer"
          v-validate="'required'"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('fail_reason')">
          {{ errors.first('fail_reason') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-if="tracking.status === 'success'">
        <label class="vs-input--label">Next Outbound</label>
        <datepicker
          v-model="tracking.follow_up_at"
          placeholder="Select Date"
          name="follow_up_at"
          color="dark"
          class="w-full"
          :config="configDateTimePicker" />
        <span class="text-danger text-sm block mt-2" v-show="errors.has('follow_up_at')">
          {{ errors.first('follow_up_at') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-if="tracking.status === 'success' && profile.is_customer_converted === false">
        <label class="vs-input--label">Converted Customer</label>
        <vs-switch color="success" v-model="tracking.customer_converted"/>
      </div>
    </div>

    <hr class="my-4">
    <div class="vx-col w-full text-center">
      <vs-button
        @click.prevent="submit"
        class="mx-1">
        Save
      </vs-button>
      <vs-button
        type="border"
        color="warning"
        @click.prevent="$emit('remove')"
        class="mx-1">
        Cancel
      </vs-button>
    </div>

  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import Datepicker from 'vuejs-datepicker'
import Datepicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
import { Validator } from 'vee-validate'
import schemas from '@/utils/schemas'
import validateMessage from '@/translations/validate/member/tracking'

Validator.localize('en', { custom: validateMessage })

// export const defaultTracking = () => ({
//   tracking_number: null,
//   type: 'outbound',
//   tracking_topic_id: null,
//   tracking_product_id: null,
//   notes: null,
//   status: null,
//   tracking_reason_id: null,
//   follow_up_at: null,
//   admin: null
// })

export default {
  name: 'Tracking',
  filters: {
    labelCode (value) {
      return value.map(({ id, title }) => ({
        label: title,
        code: id,
      }))
    },
  },
  data:() => ({
    schema: schemas.tracking(),
    tracking: schemas.tracking(),
    dateFormat: 'DD MMM YYYY HH:mm',
    topicId: null,
    configDateTimePicker: {
      enableTime: true,
      dateFormat: 'd M Y H:i',
      time_24hr: true,
      plugins: [new ConfirmDatePlugin()],
    },
  }),
  components: {
    Datepicker,
  },
  computed: {
    ...mapGetters('data', {
      topics: 'trackingTopics',
      // products: 'trackingProducts',
      products: 'products',
      stores: 'stores',
      failReasons: 'trackingReasons',
      outboundStages: 'outboundStages',
    }),
    ...mapGetters('tracking', {
      // topics: 'topics',
      // products: 'products',
      // failReasons: 'failReasons',
      $tracking: 'info',
    }),
    ...mapGetters('member', ['member', 'profile', 'masterChild', 'firstChild', 'secondChild']),

    title () {
      if (!this.lodash.isEmpty(this.lodash.get(this.tracking, 'tracking_number'))) {
        return `Tracking #${this.tracking.tracking_number}`
      }
      return 'New Tracking'
    },

    createdAt () {
      if (this.lodash.get(this.tracking, 'created_at')) {
        return this.$moment(this.lodash.get(this.tracking, 'created_at')).format('LLL')
      }

      return this.$moment().format('LLL')
    },

    createdBy () {
      return this.lodash.get(this.tracking, 'created_by', this.activeUser.displayName)
    },

    activeUser () {
      return this.$store.state.AppActiveUser
    },

    topicsByType () {
      return this.topics.filter((topic) => topic.types.includes(this.tracking.type))
    },
    topicChildren () {
      const currentTopic = this.topics.find(({ id }) => id === this.topicId)

      return this.lodash.get(currentTopic, 'children.data', [])
    },
    optionsTopic () {
      return this.topics.filter((topic) => topic.types.includes(this.tracking.type))
        .map(({ id, title }) => ({
          label: title,
          code: id,
        }))
    },
    optionsSubTopic () {
      const currentTopic = this.topics.find(({ id }) => id === this.topicId)

      return this.lodash.get(currentTopic, 'children.data', [])
        .map(({ id, title }) => ({
          label: title,
          code: id,
        }))
    },
    optionsProduct () {
      return this.products
        .filter((product) => {
          return ((!this.lodash.isEmpty(this.tracking.type)
            && this.lodash.isArray(product.types)
            && !this.lodash.isEmpty(this.lodash.intersection(['inbound', 'outbound'], product.types))
            && product.types.includes(this.tracking.type)
            && product.is_active)
            || product.slug === this.tracking.tracking_product)
        })
        .map((product) => ({
          label: product.title,
          slug: product.slug,
        }))
    },
    optionsOutboundStage () {
      return this.outboundStages.map((stage) => ({
        label: stage.title,
        value: stage.id,
      }))
    },
    optionsStore () {
      return this.stores
        .filter((store) => {
          return (store.is_active
            || store.slug === this.tracking.tracking_store)
        })
        .map((store) => ({
          label: store.title,
          slug: store.slug,
        }))
    },
    currentStageTitle () {
      return this.masterChild?.outbound_stage?.current?.title
    },
    currentStage () {
      return this.masterChild?.outbound_stage?.data?.current?.data
    },
    nextStage () {
      return this.masterChild?.outbound_stage?.data?.next?.data
    },
  },
  methods: {
    ...mapActions('data', {
      fetchProducts: 'fetchProducts',
      fetchTopic: 'fetchTrackingTopics',
      fetchStores: 'fetchStores',
      // fetchProduct: 'fetchTrackingProducts',
      fetchFailReason: 'fetchTrackingReasons',
      fetchOutboundStages: 'fetchOutboundStages',
    }),
    ...mapActions('member', {
      fetchMemberProfile: 'findProfile',
    }),

    ...mapActions('tracking', [
      // 'fetchTopic',
      // 'fetchProduct',
      // 'fetchFailReason',
      'updateOrCreate',
    ]),
    setTopicId () {
      if (this.lodash.get(this.tracking, 'tracking_topic_id')) {
        const tracking_topic_id = this.lodash.toInteger(this.lodash.get(this.tracking, 'tracking_topic_id'))
        const topic = this.topics.find(({ children: { data: subTopics } }) => (
          subTopics.some(({ id }) => parseInt(id, 10) === tracking_topic_id)
        ))

        if (topic) {
          this.topicId = topic.id
        } else {
          Object.assign(
            this.tracking,
            {
              tracking_topic_id: null,
            },
          )
        }
      }
    },
    validate () {
      return this.$validator.validateAll()
    },
    codeReducer ({ code }) {
      return code
    },
    customFormatter (date) {
      return this.$moment(date).format(this.dateFormat)
    },
    async submit () {
      const valid = await this.validate()

      if (valid) {
        this.$vs.loading()

        try {
          await this.updateOrCreate({
            id: this.member.id,
            profileId: this.profile.id,
            trackingId: this.tracking.id,
            tracking: this.tracking,
          })

          await this.fetchMemberProfile({
            id: this.member.id,
            profileId: this.profile.id,
          })

          this.$vs.notify({
            title: 'Success',
            text: 'Tracking has been created',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })

          if (this.tracking.customer_converted === true) {
            this.fetchMemberProfile({
              id: this.member.id,
              profileId: this.profile.id,
            })
          }

          this.$emit('success')
          this.$emit('remove')
        } catch (error) {
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })

          this.$emit('fail')
        } finally {
          this.$vs.loading.close()
        }
      }
    },
  },

  async mounted () {
    const fetch = []

    //if (this.topics.length === 0) { //Reload dynamic data
    fetch.push(this.fetchTopic())
    //}

    if (this.products.length === 0) {
      fetch.push(this.fetchProducts())
    }

    if (this.stores.length === 0) {
      fetch.push(this.fetchStores())
    }

    //if (this.failReasons.length === 0) { //Reload dynamic data
    fetch.push(this.fetchFailReason())
    //}

    if (this.outboundStages.length === 0) {
      fetch.push(this.fetchOutboundStages())
    }

    if (fetch.length) {
      await Promise.all(fetch)
    }

    if (this.lodash.isObject(this.$tracking)
      && !this.lodash.isNull(this.lodash.get(this.$tracking, 'id'))) {
      // Object.assign(
      //   this.tracking,
      //   this.lodash.pick(this.$tracking, Object.keys(this.schema))
      // )
      this.$store.commit(
        'tracking/SET_INFO',
        this.lodash.pick(this.$tracking, Object.keys(this.schema)),
      )

      // this.setTopicId()

    } else {
      if (!this.lodash.isEmpty(this.masterChild, 'product')) {
        // const _products = this.lodash.get(this.masterChild, 'product') || []

        // const tracking_products = this.lodash.toArray({ ...this.optionsProduct })
        //   .filter(({ slug }) => _products.includes(slug)) || []

        Object.assign(
          this.tracking,
          {
            tracking_product: this.lodash.get(this.masterChild, 'product'),
            // tracking_products
          },
        )
      }

      if (!this.lodash.isEmpty(this.masterChild, 'store')) {
        Object.assign(
          this.tracking,
          {
            tracking_store: this.lodash.get(this.masterChild, 'store'),
            // tracking_products
          },
        )
      }

      Object.assign(
        this.tracking,
        {
          outbound_stage_id: this.lodash.get(this.masterChild, 'outbound_stage.current.id'),
        },
      )
    }
  },

  watch: {
    topicId () {
      // this.lodash.set(this.tracking, 'tracking_topic_id', null)
      // console.log({
      //   watch: 'topicId'
      // })
      if (!this.optionsSubTopic.some(({ code }) => code === this.lodash.get(this.tracking, 'tracking_topic_id'))) {
        this.lodash.set(this.tracking, 'tracking_topic_id', null)
      }
    },
    '$tracking' () {
      if (!this.lodash.isObject(this.tracking)) {
        this.lodash.set(this, 'tracking', schemas.tracking())
      }

      Object.assign(
        this.tracking,
        this.lodash.pick(this.$tracking, Object.keys(this.schema)),
      )

      if (this.tracking.follow_up_at !== null) {
        this.tracking.follow_up_at = this.customFormatter(this.tracking.follow_up_at)
      }

      this.setTopicId()
      // console.log({ watch: '$tracking', to })
    },
    ['tracking.type'] () {
      // console.log({ watch: 'tracking.type', to })

      if (!this.optionsTopic.some(({ code }) => code === this.topicId)) {
        this.lodash.set(this, 'topicId', null)
        this.lodash.set(this.tracking, 'tracking_topic_id', null)
      }

      if (!this.optionsSubTopic.some(({ code }) => code === this.lodash.get(this.tracking, 'tracking_topic_id'))) {
        this.lodash.set(this.tracking, 'tracking_topic_id', null)
      }
      // this.lodash.set(this, 'topicId', null)
      // this.lodash.set(this.tracking, 'tracking_topic_id', null)
    },
    // ['tracking.tracking_topic_id'] (to) {
    //   console.log({
    //     watch: 'tracking.tracking_topic_id',
    //     to
    //   })
    // },
    // ['tracking.tracking_products'] (to) {
    //   console.log({
    //     action: 'watch',
    //     to,
    //     tracking_products: this.tracking.tracking_products
    //   })
    // },
    ['tracking.status'] (to) {
      // console.log({ watch: 'tracking.status', to })
      if (to === 'success') {
        if ([this.currentStage?.group, this.nextStage?.group].every((group) => group === 'pregnancy')) {
          this.tracking.follow_up_at = this.$moment().add(3, 'months').format('YYYY-MM-DD hh:mm')
        } else if (this.currentStage?.group === 'pregnancy' && (!this.nextStage || this.nextStage?.group !== 'pregnancy')) {
          this.tracking.follow_up_at = this.$moment(this.masterChild?.estimated_due_date).add(1, 'month')
        } else if ([this.currentStage?.group, this.nextStage?.group].every((group) => group === 'born')) {
          this.tracking.follow_up_at = this.$moment(this.masterChild?.birthdate).add(this.nextStage.range.begin, this.nextStage.range.unit).format('YYYY-MM-DD hh:mm')
        }
      }
    },
    optionsProduct () {
      if (!this.lodash.isEmpty(this.tracking.type)
        && !this.lodash.isEmpty(this.optionsProduct)
        && !this.lodash.some(this.optionsProduct, { slug: this.tracking.tracking_product })) {
        this.tracking.tracking_product = null
      }
    },
    optionsStore () {
      if (!this.lodash.isEmpty(this.tracking.type)
        && !this.lodash.isEmpty(this.optionsStore)
        && !this.lodash.some(this.optionsStore, { slug: this.tracking.tracking_store })) {
        this.tracking.tracking_store = null
      }
    },
  },
}
</script>
