<template>
  <vs-table
    stripe
    v-model="selected"
    :hoverFlat="true"
    :data="trackings" >
    <template slot="thead">
      <vs-th class="thead">Created At</vs-th>
      <vs-th class="thead">Tracking Number</vs-th>
      <vs-th class="thead">Handling</vs-th>
      <vs-th class="thead">Topic</vs-th>
      <vs-th class="thead">Next Follow Up</vs-th>
      <vs-th class="thead">Status</vs-th>
      <vs-th class="thead">Created By</vs-th>
      <vs-th class="thead">Action</vs-th>
    </template>
    <template slot-scope="{data}">
      <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
        <vs-td>
          {{ $moment(item.created_at).format(datetimeFormat) }}
        </vs-td>
        <vs-td>
          {{ item.tracking_number }}
        </vs-td>
        <vs-td>
          {{ lodash.startCase(lodash.get(item, 'type', '-')) }}
        </vs-td>
        <vs-td :data="item.tracking_topic">
          {{ lodash.get(item, 'tracking_topic.title', '-') }}
        </vs-td>
        <vs-td>
          {{ item.follow_up_at ? $moment(item.follow_up_at).format(dateFormat) : '-' }}
        </vs-td>
        <vs-td>
          {{ lodash.startCase(lodash.get(item, 'status', '-')) }}
        </vs-td>
        <vs-td>
          {{ lodash.get(item, 'created_by', '-') }}
        </vs-td>
        <vs-td>
          <vs-button
            class="float-left m-0"
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-edit-2"
            @click="onEdit(item)"/>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import schemas from '@/utils/schemas'

export default {
  name: 'TrackingHistoryTable',
  filters: {
    getTitle (value) {
      return value?.data?.title
    },
  },
  computed: {
    ...mapGetters('member', [
      'member',
      'profile',
      // 'masterChild',
      'trackings',
      'tracking',
    ]),
  },
  data:() => ({
    selected: [],
    datetimeFormat: 'D MMM YYYY HH:mm',
    dateFormat: 'D MMM YYYY HH:mm',
  }),
  methods: {
    ...mapActions('member', ['fetchTracking']),

    onEdit (data) {
      this.selected = data

      // console.log({ member: this.member, profile: this.profile, masterChild: this.masterChild })

      this.fetchTracking({
        id: this.member.id,
        profileId: this.profile.id,
        trackingId: data.id,
      })
    },
  },

  watch: {
    tracking (to) {
      this.$emit('edit', to)
    },
  },
}
</script>
