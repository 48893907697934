<template>
  <div class="member-address">
    <div class="vx-row mb-4">
      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input
          v-model="address.house"
          name="house"
          class="w-full"
          label="House Number"
          color="dark"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('house')">
          {{ errors.first('house') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input
          v-model="address.street"
          name="street"
          class="w-full"
          label="Street"
          color="dark"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('street')">
          {{ errors.first('street') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">City</label>
        <v-select
          v-model="address.city"
          name="city"
          class="w-full"
          color="dark"
          :options="cities"
          label="name"
          @input="(value) => address.city = value.name"
          v-validate="'required'"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('city')">
          {{ errors.first('city') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">District</label>
        <v-select
          v-model="address.district"
          name="district"
          class="w-full"
          color="dark"
          :options="districts"
          label="name"
          @input="(value) => address.district = value.name"
          :disabled="!address.city"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('district')">
          {{ errors.first('district') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Ward</label>
        <v-select
          v-model="address.ward"
          name="ward"
          class="w-full"
          color="dark"
          :options="wards"
          label="name"
          @input="(value) => address.ward = value.name"
          :disabled="!address.district"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('ward')">
          {{ errors.first('ward') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input v-model="address.zip_code"
          name="zip_code"
          class="w-full"
          label="Zip Code"
          color="dark"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('zip_code')">
          {{ errors.first('zip_code') }}
        </span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full text-center">
        <vs-button
          class="mx-auto"
          icon-pack="feather"
          icon="icon-save"
          @click.prevent="updateAddress"
          :disabled="locked">
          Update Address
        </vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Validator } from 'vee-validate'
import vSelect from 'vue-select'
import schemas from '@/utils/schemas'
import validateMessage from '@/translations/validate/member/address'

Validator.localize('en', { custom: validateMessage })

export default {
  name: 'MemberAddress',
  components: {
    vSelect,
  },
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      schema: schemas.memberAddress(),
      address: schemas.memberAddress(),
    }
  },
  computed: {
    ...mapGetters('data', ['cities']),
    ...mapGetters('member', {
      member: 'member',
      profile: 'profile',
      $address: 'address',
    }),

    districts () {
      if (this.cities === undefined
        || this.cities.length === 0) {
        return []
      }

      const _city = this.cities.find((city) => city.name === this.address.city)

      return _city?.districts?.data || []
    },

    wards () {
      if (this.cities === undefined
        || this.cities.length === 0
        || !this.address.district
        || this.address.district.length === 0) {
        return []
      }

      const _district = this.districts.find((district) => district.name === this.address.district)

      return _district?.wards?.data || []
    },
  },
  methods: {
    ...mapActions('data', ['fetchCities']),
    ...mapActions('member', ['saveAddress']),

    validate () {
      return this.$validator.validateAll()
    },
    save () {
      return this.saveAddress({
        id: this.member.id,
        profileId: this.profile.id,
        address: this.address,
      })
    },

    async updateAddress () {
      const valid = await this.validate()

      if (valid) {
        this.$emit('locked', true)

        await Promise.all([this.save()])
          .then(() => {
            this.$vs.notify({
              title: 'Success',
              text: 'Address has been updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Error',
              text: 'Update fail',
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger',
            })
          })
          .finally(() => {
            this.$emit('locked', false)
          })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
      }
    },
  },
  mounted () {
    if (this.cities.length === 0) {
      this.fetchCities()
    }

    if (!this.lodash.isEmpty(this.$address)) {
      Object.assign(
        this.address,
        this.lodash.pick(this.$address, Object.keys(this.schema)),
      )
    }
  },
  watch: {
    $address (to) {
      Object.assign(
        this.address,
        this.lodash.pick(to, Object.keys(this.schema)),
      )
    },
  },
}
</script>
