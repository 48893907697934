export default {
  handling: {
    required: 'Please select handling',
  },
  topic: {
    required: 'Please select topic',
  },
  subtopic: {
    required: 'Please select sub topic',
  },
  products: {
    required: 'Please select products of used',
  },
  product: {
    required: 'Please select drinking product',
  },
  store: {
    required: 'Please select store',
  },
  worknote: {
    required: 'Please fill work note',
  },
  status: {
    required: 'Please select call status',
  },
  fail_reason: {
    required: 'Please select fail reason',
  },
}
