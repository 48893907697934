<template>
  <div class="member-gift-set">

    <div class="vx-row">
      <div class="vx-col w-full">

        <div class="pb-2">
          <div
            v-for="giftSet in giftSets"
            :key="giftSet.id">
            <div class="flex justify-between my-3">
              <div class="flex items-center"
                :class="[{ 'text-success': hasGiftSet(giftSet.slug) }]">
                <feather-icon
                  icon="GiftIcon"
                  :svgClasses="[{'stroke-current': hasGiftSet(giftSet.slug)}, 'h-6 w-6']"/>
                <span class="text-lg ml-3">
                  {{ giftSet.name }}
                </span>
              </div>

              <div class="flex items-center">

                <vs-button v-if="!hasGiftSet(giftSet.slug)"
                  class="ml-2"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus-circle"
                  @click.prevent="requestGiftSet(giftSet)">
                  Request {{ giftSet.name }}
                </vs-button>

                <vs-button v-if="hasGiftSet(giftSet.slug) && findGiftSet(giftSet.slug).status === 'waiting'"
                  class="ml-2"
                  color="warning"
                  type="border"
                  icon-pack="feather"
                  icon="icon-loader">
                  Waiting
                </vs-button>

                <vs-button v-if="hasGiftSet(giftSet.slug) && findGiftSet(giftSet.slug).status === 'approved'"
                  class="ml-2"
                  color="success"
                  type="border"
                  icon-pack="feather"
                  icon="icon-check-circle">
                  Approved
                </vs-button>

                <vs-button v-if="hasGiftSet(giftSet.slug) && findGiftSet(giftSet.slug).status === 'rejected'"
                  class="ml-2"
                  color="danger"
                  type="border"
                  icon-pack="feather"
                  icon="icon-x-circle">
                  Rejected
                </vs-button>

                <vs-button v-if="hasGiftSet(giftSet.slug) && findGiftSet(giftSet.slug).status === 'completed'"
                  class="ml-2"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-archive">
                  Completed
                </vs-button>

                <vs-button v-if="hasGiftSet(giftSet.slug) && findGiftSet(giftSet.slug).status === 'canceled'"
                  class="ml-2"
                  color="danger"
                  type="border"
                  icon-pack="feather"
                  icon="icon-x-circle">
                  Canceled
                </vs-button>

              </div>
            </div>

            <div v-if="hasActivityTimeline(giftSet.slug)"
              class="flex flex-col items-center my-5 md:ml-8">
              <vx-card class="timeline">
                <vx-timeline class="w-full"
                  :data="giftSetsActivityTimeline(giftSet.slug)" />
              </vx-card>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VxTimeline from '@/components/timeline/VxTimeline.vue'

export default {
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VxTimeline,
  },
  data () {
    return {
      lists: [],
    }
  },
  computed: {
    ...mapGetters('data', ['giftSets']),
    ...mapGetters('member', {
      member: 'member',
      profile: 'profile',
      $giftSets: 'giftSets',
    }),
    // birthdaySet: {
    //   get() {
    //     return true
    //   },
    //   set(value) {

    //   }
    // }
  },
  methods: {
    ...mapActions('data', ['fetchGiftSets']),
    ...mapActions('member', ['saveGiftSets', 'makeGiftSetRequest']),

    dateDisplay (date) {
      if (this.lodash.isEmpty(date)) {
        return ''
      }

      return this.$helpers.dateFormat(date, 'LLL')
    },
    findGiftSet (slug) {
      return this.lodash.get(this.lists, 'current', []).find((e) => e.slug === slug)
    },
    hasGiftSet (slug) {
      return this.findGiftSet(slug) !== undefined
    },
    getActivityTimeline (slug) {
      return this.lodash.get(this.lists, 'history', [])
        .filter((e) => this.lodash.get(e, 'gift_set.slug') === slug)
    },
    hasActivityTimeline (slug) {
      return !this.lodash.isEmpty(this.getActivityTimeline(slug))
    },
    giftSetsActivityTimeline (slug) {
      return this.getActivityTimeline(slug)
        .map((history) => {
          const schema = {
            key: `history-${history.id}`,
            color: null,
            icon: null,
            title: `${history.title} by ${history.created_by}`,
            desc: history.message,
            time: this.$helpers.dateFormat(history.created_at, 'humanize'),
          }

          switch (history.status) {
          case 'waiting':
            schema.color = 'primary'
            schema.icon = 'PlusCircleIcon'
            break

          case 'approved':
            schema.color = 'success'
            schema.icon = 'CheckCircleIcon'
            break

          case 'rejected':
            schema.color = 'warning'
            schema.icon = 'XCircleIcon'
            break

          case 'process':
            schema.color = 'success'
            schema.icon = 'LoaderIcon'
            break

          case 'completed':
            schema.color = 'success'
            schema.icon = 'ArchiveIcon'
            break

          case 'canceled':
            schema.color = 'warning'
            schema.icon = 'XCircleIcon'
            break
          }

          return schema
        })

      // return this.lodash.toArray(activity).map((activity) => {
      //   const schema = {
      //     key: `${giftSet.slug}-${activity.id}`,
      //     color: null,
      //     icon: null,
      //     title: `${activity.title} by ${activity.created_by}`,
      //     desc: activity.message,
      //     time: this.$helpers.dateFormat(activity.created_at, 'humanize')
      //   }

      //   switch (activity.status) {
      //   case 'waiting':
      //     schema.color = 'primary'
      //     schema.icon = 'PlusCircleIcon'
      //     break

      //   case 'approved':
      //     schema.color = 'success'
      //     schema.icon = 'CheckCircleIcon'
      //     break

      //   case 'rejected':
      //     schema.color = 'warning'
      //     schema.icon = 'XCircleIcon'
      //     break

      //   case 'completed':
      //     schema.color = 'success'
      //     schema.icon = 'ArchiveIcon'
      //     break

      //   case 'cancleed':
      //     schema.color = 'warning'
      //     schema.icon = 'XCircleIcon'
      //     break
      //   }

      //   return schema
      // })
    },
    validate () {
      return this.$validator.validateAll()
    },
    store (giftSet) {
      return this.makeGiftSetRequest({
        id: this.member.id,
        profileId: this.profile.id,
        giftSet: {
          gift_set: giftSet.id,
        },
      })
    },

    async requestGiftSet (giftSet) {
      const self = this

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Gift Sets',
        text: `Do you want to make request “${giftSet.name}”.`,
        acceptText: 'Confirm',
        accept: async () => {
          self.$emit('locked', true)

          await Promise.all([self.store(giftSet)])
            .then(() => {
              this.$vs.notify({
                title: 'Success',
                text: 'Gift set has been requested.',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
              })
            })
            .catch(() => {
              self.$vs.notify({
                title: 'Error',
                text: 'Request fail',
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger',
              })
            })
            .finally(() => {
              self.$emit('locked', false)
            })
        },
      })
    },
  },
  async mounted () {
    if (this.giftSets.length === 0) {
      await this.fetchGiftSets()
    }

    if (!this.lodash.isEmpty(this.$giftSets)) {
      this.lists = this.$giftSets
    }

  },
  watch: {
    $giftSets (to) {
      this.lists = to
    },
  },
}
</script>
<style>
  .vx-timeline > li:last-child {
    margin-bottom: 0;
  }
</style>
