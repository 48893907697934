<template>
  <div class="member-children">
    <children-first-child ref="firstChild" class="mb-4" />

    <div class="vx-row">
      <div class="vx-col w-full text-center">
        <vs-button
          class="mx-auto"
          icon-pack="feather"
          icon="icon-save"
          @click.prevent="updateChildren"
          :disabled="locked">
          Update Children
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>

import ChildrenFirstChild from '@/components/form/member/children/firstChild'

export default {
  name: 'MemberChildren',
  components: {
    ChildrenFirstChild,
  },
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async validate () {
      const validates = [this.$refs.firstChild.validate()]

      const valids = await Promise.all(validates)

      return valids.every(valid => valid)
    },
    async updateChildren () {
      const valid = await this.validate()

      if (valid) {
        this.$emit('locked', true)

        const updates = [this.$refs.firstChild.save()]

        await Promise.all(updates)
          .then(() => {
            this.$vs.notify({
              title: 'Success',
              text: 'Children has been updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Error',
              text: 'Update fail',
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger',
            })
          })
          .finally(() => {
            this.$emit('locked', false)
          })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
      }
    },
  },
}
</script>
