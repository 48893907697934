<template>
  <div class="member-profile">
    <div class="vx-row mb-4">
      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input
          v-model="profile.name"
          name="name"
          class="w-full"
          label="Name"
          color="dark"
          v-validate="'required'"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('name')">
          {{ errors.first('name') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="vs-input--label">Birthdate</label>
        <datepicker
          v-model="profile.birthdate"
          name="birthdate"
          placeholder="Select Date"
          color="dark"
          :format="datePicker.format"
          :disabled-dates="datePicker.disabledDates" />
        <span class="text-danger text-sm block mt-2" v-show="errors.has('birthdate')">
          {{ errors.first('birthdate') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label for="telephone">Contact Number</label>
        <vx-input-group>
          <template slot="prepend">
            <div class="prepend-text bg-primary">
              <span>84</span>
            </div>
          </template>
        <vs-input
          v-model="telephone"
          class="w-full"
          name="contact_number"
          color="dark"
          v-validate="{ required: true, numeric: true, min: 7, max: 10, regex: /^[0-9]{1,10}/ }"/>
        </vx-input-group>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('contact_number')">
          {{ errors.first('contact_number') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <vs-input
          v-model="profile.email"
          name="email"
          class="w-full"
          type="email"
          label="Email"
          color="dark"
          v-validate="{ email: true }"/>
        <span class="text-danger text-sm block mt-2" v-show="errors.has('email')">
          {{ errors.first('email') }}
        </span>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2">
        <label class="flex mb-2">Contact Channel</label>
        <div class="w-full">
          <vs-checkbox
            v-model="profile.contact_channel"
            vs-value="address"
            name="contact_channel_address"
            class="inline-flex"
            color="primary">
            Address
          </vs-checkbox>
          <vs-checkbox
            v-model="profile.contact_channel"
            name="contact_channel_channel"
            vs-value="phone"
            class="inline-flex"
            color="primary">
            Phone
          </vs-checkbox>
          <vs-checkbox
            v-model="profile.contact_channel"
            name="contact_channel_sms"
            vs-value="sms"
            class="inline-flex"
            color="primary">
            SMS
          </vs-checkbox>
          <vs-checkbox
            v-model="profile.contact_channel"
            name="contact_channel_email"
            vs-value="email"
            class="inline-flex"
            color="primary">
            Email
          </vs-checkbox>
        </div>
      </div>

      <div class="vx-col mb-4 w-full md:w-1/2" v-if="profile.is_customer_converted">
        <vs-input
          name="customer_converted"
          class="w-full"
          type="text"
          label="Customer Converted At"
          color="dark"
          :value="profile.customer_converted_at | moment('LLL')"
          disabled/>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full text-center">
        <vs-button
          class="mx-auto"
          icon-pack="feather"
          icon="icon-save"
          @click.prevent="updateProfile"
          :disabled="locked">
          Update Profile
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Validator } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import schemas from '@/utils/schemas'
import validateMessage from '@/translations/validate/member/profile'

Validator.localize('en', { custom: validateMessage })

export default {
  props: {
    locked: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Datepicker,
  },
  data () {
    return {
      schema: schemas.memberProfile(),
      profile: schemas.memberProfile(),
      datePicker: {
        format: 'd MMMM yyyy',
        disabledDates: {
          from: new Date(),
        },
      },
      telephone: (this.profile ? this.profile.phone_number.replace(/^(84)/, '') : ''),
    }
  },
  computed: {
    ...mapGetters('member', {
      member: 'member',
      $profile: 'profile',
    }),
  },
  methods: {
    ...mapActions('member', ['saveProfile']),
    validate () {
      return this.$validator.validateAll()
    },
    save () {
      return this.saveProfile({
        id: this.member.id,
        profile: this.profile,
      })
    },

    async updateProfile () {
      const valid = await this.validate()

      if (valid) {
        this.$emit('locked', true)

        await Promise.all([this.save()])
          .then(() => {
            this.$vs.notify({
              title: 'Success',
              text: 'Profile has been updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          })
          .catch(() => {
            this.$vs.notify({
              title: 'Error',
              text: 'Update fail',
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger',
            })
          })
          .finally(() => {
            this.$emit('locked', false)
          })
      } else {
        this.$vs.notify({
          title: 'Error',
          text: 'Please complete this form',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'warning',
        })
      }
    },
  },

  mounted () {
    if (this.lodash.isObject(this.$profile)) {
      Object.assign(
        this.profile,
        this.lodash.pick(this.$profile, Object.keys(this.schema)),
      )
      this.telephone = this.profile.phone_number.replace(/^(84)/, '')
    }
  },
  watch: {
    $profile (to) {
      Object.assign(
        this.profile,
        this.lodash.pick(to, Object.keys(this.schema)),
      )
      this.telephone = this.profile.phone_number.replace(/^(84)/, '')
      // console.log({ to, profile: this.profile, schema: this.schema })
    },
    telephone (to) {
      this.profile.phone_number = `84${to}`
    },
  },
}
</script>
