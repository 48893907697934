<template>
  <section>
    <vs-row vs-justify="center" class="vx-row w-auto">
      <!-- Member -->
      <vs-col class="vx-col w-full">
        <vx-card class="cardx">
          <vs-tabs class="tab-action-btn-fill-conatiner">
            <vs-tab label="Profile" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <member-profile
                  ref="profile"
                  :locked="locked"
                  @locked="(value) => (this.locked = value)"
                />
              </div>
            </vs-tab>
            <vs-tab label="Address" icon-pack="feather" icon="icon-map-pin">
              <div class="tab-text">
                <member-address
                  ref="address"
                  :locked="locked"
                  @locked="(value) => (this.locked = value)"
                />
              </div>
            </vs-tab>
            <vs-tab label="Children" icon-pack="feather" icon="icon-users">
              <div class="tab-text">
                <member-children
                  ref="children"
                  :locked="locked"
                  @locked="(value) => (this.locked = value)"
                />
              </div>
            </vs-tab>
            <vs-tab label="Gift Sets" icon-pack="feather" icon="icon-gift">
              <div class="tab-text">
                <gift-set
                  ref="giftSet"
                  :locked="locked"
                  @locked="(value) => (this.locked = value)"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </vs-col>

      <!-- Tracking History -->
      <vs-col class="vx-col w-full">
        <vx-card class="cardx" title="Tracking History">
          <tracking-history-table @edit="openTrackingForm" />

          <hr v-show="!tracking" class="my-4" />

          <div class="vx-row">
            <div class="vx-col w-full text-center">
              <vs-button
                v-show="!tracking"
                transition="fadeOut"
                class="mx-auto"
                icon-pack="feather"
                icon="icon-plus-square"
                @click="openTrackingForm"
                :disabled="locked"
              >
                Create New Tracking
              </vs-button>
            </div>
          </div>
        </vx-card>

        <tracking
          v-if="tracking"
          ref="tracking"
          transition="fadeIn"
          @success="reloadTrackingHistory"
          @remove="closeTrackingForm"
        />
      </vs-col>
    </vs-row>

    <!-- Back Button -->
    <vs-row vs-justify="flex-start" class="my-4">
      <vs-button
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-arrow-left"
        id="btnBack"
        @click.prevent="$router.go(-1)"
      >
        Back
      </vs-button>
      <vs-spacer />
      <vs-button
        :data="childNext"
        v-if="childNext != null && childNext.child_id != null"
        color="primary"
        type="flat"
        icon-pack="feather"
        icon="icon-arrow-right"
        icon-after
        id="btnNext"
        @click="reloadProfile()"
      >
        Next
      </vs-button>
    </vs-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import infoMixin from '@/mixins/infoMixin'
import MemberProfile from '@/components/form/member/profile'
import MemberAddress from '@/components/form/member/address'
import MemberChildren from '@/components/form/member/children'
import TrackingHistoryTable from '@/components/table/TrackingHistory'
import { default as Tracking } from '@/components/form/tracking'
import GiftSet from '@/components/form/member/giftSet'
import schemas from '@/utils/schemas'
import router from '@/router'

export default {
  mixins: [infoMixin],
  components: {
    MemberProfile,
    MemberAddress,
    MemberChildren,
    TrackingHistoryTable,
    Tracking,
    GiftSet,
  },
  data: () => ({
    breadcrumb: [{ title: 'Update Member', active: true }],
    newTracking: false,
    activeTab: 0,
  }),
  methods: {
    ...mapActions('member', [
      'find',
      'findProfile',
      'deleteChild',
      'fetchTrackings',
      'reset',
    ]),
    openTrackingForm (data = null) {
      // console.log({ methods: 'openTrackingForm', data })
      const _schema = schemas.tracking()

      if (this.lodash.isObject(data)) {
        Object.assign(_schema, data)
      }

      // console.log({ _schema })
      this.$store.commit('tracking/SET_INFO', _schema)
    },
    closeTrackingForm () {
      this.$store.commit('tracking/RESET_INFO')
    },

    reloadTrackingHistory () {
      const { memberId: id, profileId } = this.$route.params

      this.fetchTrackings({
        id,
        profileId,
      })
    },

    reloadProfile () {
      router.push({
        name: 'outbound.edit',
        params: {
          memberId: this.childNext.member_id,
          profileId: this.childNext.member_profile_id,
        },
        query: {
          ['new-case']: true,
        },
      })
    },

    async loadProfile () {
      const { memberId, profileId } = this.$route.params

      if (memberId) {
        await this.findProfile({
          id: parseInt(memberId, 10),
          profileId: parseInt(profileId, 10),
        })

        this.reloadTrackingHistory()
      }

      if (this.lodash.has(this.$route, 'query[new-case]')) {
        this.openTrackingForm()
      }

      this.locked = false
    },
  },
  computed: {
    ...mapGetters('member', [
      'member',
      'profile',
      'firstChild',
      'secondChild',
      'childNext',
    ]),
    ...mapGetters('tracking', {
      tracking: 'info',
    }),

    isPregnancy: {
      get () {
        return this.lodash.get(this, 'firstChild.pregnant', false) === true
      },
      set (value) {
        this.$set(this.firstChild, 'pregnant', value)
      },
    },
    hasSecondChild: {
      get () {
        return !this.secondChild?.delete
      },
      set (value) {
        this.$set(this.secondChild, 'delete', !value)
      },
    },
  },
  watch: {
    '$route.params.profileId' () {
      this.loadProfile()
    },
  },
  beforeMount () {},
  async mounted () {
    await this.loadProfile()
  },
}
</script>

<style lang="scss">
.cardx {
  margin-bottom: 20px;
}
.cardx .vx-card__body,
.cardx .vx-card__body .con-vs-tabs,
.cardx .vx-card__body .con-vs-tabs .con-slot-tabs {
  overflow: visible !important;
}
#btnBack .vs-button-backgroundx,
#btnNext .vs-button-backgroundx {
  background: transparent !important;
  color: rgb(234, 84, 85);
}
#btnBack.isActive *,
#btnNext.isActive * {
  color: rgb(234, 84, 85) !important;
}
</style>
