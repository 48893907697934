import schemas from '@/utils/schemas'
import {mapActions, mapGetters} from 'vuex'

const Edd = () => import('@/components/form/member/children/edd')
const Dob = () => import('@/components/form/member/children/dob')

export default {
  components: {
    Edd,
    Dob,
  },
  data () {
    return {
      schema: schemas.memberChildren(),
      child: schemas.memberChildren(),
    }
  },
  computed: {
    ...mapGetters('member', ['member', 'profile']),
  },
  methods: {
    ...mapActions('member', ['deleteChild']),
    filterCurrentOutboundStage (outboundStage) {
      const currentStage = this.lodash.get(outboundStage, 'current')
      if (!currentStage) {
        return null
      }

      const { id, title } = currentStage
      return Object.assign({}, {
        id,
        title,
      })
    },
    save () {
      return this.$refs.form?.save() || Promise.resolve(true)
    },
    saveOrDelete () {
      if (this.$refs.form) {
        return this.$refs.form.save()
      } else if (this.child.delete && this.child.id) {
        return this.deleteChild({ id: this.member.id, profileId: this.profile.id, childId: this.child.id })
      }
      return Promise.resolve(true)
    },
    validate () {
      return this.$refs.form?.validate() || true
    },
  },
  mounted () {
    if (!this.lodash.isEmpty(this.$child)) {
      const _schema = this.schema

      Object.assign(_schema, this.lodash.pick(this.$child, Object.keys(_schema)))

      // current outbound stage
      const currentStage = this.lodash.get(this.$child, 'outbound_stage.current')
      if (!this.lodash.isEmpty(currentStage)) {
        Object.assign(_schema, {
          current_outbound_stage: this.lodash.pick(currentStage, ['id', 'title']),
        })
      }

      // show or hide
      // _schema.delete = !to

      Object.assign(this.child, _schema)
    }
  },
  watch: {
    $child (to) {
      const _schema = this.schema

      Object.assign(_schema, this.lodash.pick(to, Object.keys(_schema)))

      // current outbound stage
      const currentStage = this.lodash.get(to, 'outbound_stage.current')
      if (!this.lodash.isEmpty(currentStage)) {
        Object.assign(_schema, {
          current_outbound_stage: this.lodash.pick(currentStage, ['id', 'title']),
        })
      }

      // show or hide
      _schema.delete = !to

      Object.assign(this.child, _schema)
    },
  },
}
