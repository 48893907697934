export default {
  house_number: {
    required: 'Please enter house number',
  },
  street: {
    required: 'Please enter street',
  },
  ward: {
    required: 'Please enter ward',
  },
  district: {
    required: 'Please select district',
  },
  city: {
    required: 'Please select city',
  },
  zip_code: {
    required: 'Please enter zip code',
  },
}
