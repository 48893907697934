<template>
  <div class="first-child">
    <h5 class="flex mb-2 text-primary">Children</h5>
    <hr class="mb-6">

    <div class="vx-row">
      <div class="vx-col mb-4 w-full">
        <label class="vs-input--label">Pregnancy</label>
        <div class="vs-con-input mt-2">
          <ul class="flex">
            <li class="mx-1">
              <vs-radio v-model="child.pregnant"
                vs-name="pregnancy"
                name="pregnant"
                :vs-value="true"
                v-validate="'required'">
                Yes
              </vs-radio>
            </li>
            <li class="mx-1">
              <vs-radio v-model="child.pregnant"
                vs-name="pregnancy"
                name="pregnant"
                :vs-value="false"
                v-validate="'required'">
                No
              </vs-radio>
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-col mb-4 w-full">
        <component
          ref="form"
          :is="child.pregnant ? 'edd' : 'dob'"
          :child="child"
          prefix="first_child"
          class="mt-4"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { default as childMixin } from '@/mixins/member/childMixin'

export default {
  mixins: [childMixin],
  computed: {
    ...mapGetters('member', {
      $child: 'firstChild',
    }),
  },
}
</script>
