export default {
  name: {
    required: 'Please enter name',
  },
  birthdate: {
    required: 'Please enter date of birth',
  },
  contact_number: {
    required: 'Please enter contact phone number',
  },
}
